import React from "react";
import styled from "styled-components";
import { renderToStaticMarkup } from "react-dom/server";
import { JsonLd } from "./../common/JsonLd";
import { Section, Container } from "@components/global";

import FaqItem from "@common/FaqItem";
import { Link } from "gatsby";

const FAQS = [
  {
    title:
      "Jakich dokumentów oczekuje ode mnie najtańsza wypożyczalnia samochodów RENT ME CAR?",
    content: () => (
      <>
        <p>Podczas odbioru samochodu, będą sprawdzane następujące dokumenty:</p>
        <ul>
          <li>ważny dowód osobisty</li>
          <li>aktualne prawo jazdy kategorii B</li>
        </ul>
      </>
    ),
  },
  {
    title:
      "W jakim muszę być wieku i ile lat posiadać prawo jazdy, żeby wynająć samochód?",
    content: () => (
      <>
        <p>
          Nasza wypożyczalnia samochodów w Gdańsku realizuje najem aut dla osób,
          które ukończyły 18 lat i mają ważne prawo jazdy kategorii B.
        </p>
      </>
    ),
  },
  {
    title:
      "Jak wynająć pojazd w wypożyczalni samochodów w Gdańsku, bądź na terenie Trójmiasta?",
    content: () => (
      <>
        <p>
          Jeśli chcesz wynająć auto w naszej wypożyczalni samochodów w Gdańsku,
          skorzystaj z zakładki <Link to="/samochody">Samochody</Link>, a
          następnie wybierz auto do swoich potrzeb. Następnie określ miejsce
          wypożyczenia samochodu na terenie Trójmiasta i czas. Teraz dobierz
          odpowiednie udogodnienia takie jak możliwość zwrotu brudnego
          samochodu, fotelik dla Twojego dziecka czy nawigacja GPS. Został
          ostatni krok ! Wypełnij formularz kontaktowy i czekaj na potwierdzenie
          rezerwacji przez naszą obsługę klienta.
        </p>
      </>
    ),
  },
  {
    title: "Jak wynająć auto najtaniej w Gdańsku?",
    content: () => (
      <>
        <p>
          Przede wszystkim wybierz samochód z{" "}
          <Link to="/samochody#segment-a">segmentu A</Link>,{" "}
          <Link to="/samochody#segment-a%2B%20automat">segmentu A+</Link>, bądź{" "}
          <Link to="/samochody#segment-b">segmentu B</Link>. Jeśli rzeczywiście
          interesuje Cię najtańszy wynajem samochodu w Gdańsku, wybierz samochód
          z instalacją LPG. Dzięki temu rodzajowi zasilania koszt przejechania
          100km spada o ponad połowę ! Co znacznie przekłada się na totalne
          koszty związane z wynajmowanym samochodem. Plusem instalacji LPG jest
          również zwiększony zasięg samochodu, dzięki „dwóm” zbiornikom paliwa.
        </p>
      </>
    ),
  },
  {
    title: "Jakie auto mogę wypożyczyć najtaniej w Gdańsku?",
    content: () => (
      <>
        <p>
          Jeśli w grę w chodzi kwestia ekonomiczna, a wielkość samochodu nie
          jest istotna, najtańszym rozwiązaniem będzie dla Ciebie wypożyczenie
          samochodu z <Link to="/samochody#segment-a">segmentu A</Link> z
          instalacją LPG.
        </p>
      </>
    ),
  },
  {
    title:
      "Jakie auto jest najczęściej wybranie przez klientów najtańszej wypożyczalni samochodów w Gdańsku ? ",
    content: () => (
      <>
        <p>
          Bezkompromisowym wyborem naszych klientów jest Skoda Fabia z
          instalacją LPG. Miejski model samochodu Segmentu B, z stajni
          Volkswagen Group stał się sukcesem rynkowych nie tylko czeskiego
          producenta samochodów, ale również naszym ! Zgodnie ze sloganem
          „Simply clever” samochód jest prosty w obsłudze i nieźle wygląda, a w
          dodatku spala 7 litrów gazu, co w ogólnym rozrachunku daje
          najkorzystniejsze warunki najmu dla trójmiejskiego klienta.
        </p>
      </>
    ),
  },
  {
    title: "Czy wynajęty pojazd mam zatankować do pełna?",
    content: () => (
      <>
        <p>
          Odbierając samochód z wypożyczalni, Najemca dostaje zatankowane do
          pełna, benzyną i gazem, auto, które zwraca w takim samym stanie.
          Dlatego właśnie sugerujemy odwiedzić stację benzynową tuż przed
          zwrotem samochodu do wypożyczalni.
        </p>
      </>
    ),
  },
  {
    title: "Jak tankować samochody z instalacją LPG?",
    content: () => (
      <>
        <p>
          Auta z naszej wypożyczalni, mają zazwyczaj korki wlewu gazu ukryte w
          tylnym błotniku, bądź schowane pod klapką wlewu paliwa. W pierwszym
          przypadku, należy odpowiednią stroną samochodu podjechać pod
          dystrybutor, podpiąć pistolet i zatankować gaz. W opcji numer dwa
          sytuacja jest analogiczna do pierwszej, aczkolwiek trzeba pamiętać o
          wkręceniu dodatkowej przejściówki w gniazdo LPG, ukryte pod klapką
          wlewu paliwa. Na każdej stacji paliwowej, sprzedawca ma obowiązek
          pomóc przy tankowaniu, dlatego w razie problemów, wystarczy wcisnąć
          przycisk dystrybutorze „wezwij pomoc”, a obsługa pomoże nam przy
          tankowaniu.
        </p>
      </>
    ),
  },
  {
    title: "Czy muszę wpłacać kaucję?",
    content: () => (
      <>
        <p>
          Jeśli chcesz wynająć samochód bez kaucji, wybierz w udogodnieniach
          dodatkowe ubezpieczenie, które znosi udział własny w szkodzie na
          wynajętym pojeździe. Wyjątkiem jest wynajem aut w Segmencie PREMIUM
        </p>
      </>
    ),
  },
  {
    title: "Co to jest wynajem długoterminowy?",
    content: () => (
      <>
        <p>
          Wynajem długoterminowy to zwykła umowa wypożyczenia samochodu, na
          dłużej niż 12 miesięcy. Dzięki wydłużonemu okresowi jej trwania, mamy
          pewność, że współpracujemy z pewnym klientem, a Ty możesz liczyć na
          jeszcze tańszą cenę za dobę. Wybierając wynajem długoterminowy nie
          obniżasz swojej zdolności kredytowej, ograniczasz formalności do
          minimum oraz nie martwisz się naprawami i serwisem samochodu.
        </p>
      </>
    ),
  },
  {
    title: "Jak wynająć samochód na Dworcu Głównym PKP w Gdańsku?",
    content: () => (
      <>
        <p>
          Wybierz zakładkę REZERWACJE na naszej stronie, a następnie podaj
          miejsce odbioru i zwrotu samochodu. Jeśli zdecydujesz się na Dworzec
          Główny PKP w Gdańsku, po wypełnieniu formularza wynajmu, my
          skontaktujemy się z Tobą, a następnie podstawimy auto na Dworzec
          Główny PKP o wskazanej przez Ciebie godzinie.
        </p>
      </>
    ),
  },
  {
    title: "Jak wynająć samochód w miejscu wskazanym przeze mnie?",
    content: () => (
      <>
        <p>
          Na początku wybierz zakładkę Rezerwacje, a następnie określ miejsce
          odbioru i zwrotu samochodu. Możesz skorzystać z punktów podstawienia
          samochodu, które automatycznie wybierasz z listy rozwijanej, bądź
          wpisać własny adres odbioru i zwrotu samochodu, który otrzymamy od
          Ciebie, po wyplenieniu do końca formularza rezerwacji. Następnie
          skontaktujemy się z Tobą w celu potwierdzenia wszystkich danych.
        </p>
      </>
    ),
  },
  {
    title: "W jakich godzinach jest czynna wypożyczalnia samochodów w Gdańsku?",
    content: () => (
      <>
        <p>
          Jesteśmy czynni od godziny 8:00 do 16:00 od poniedziałku do piątku, a
          w soboty od godziny 8:00, do 13:00, aczkolwiek nasza infolinia jest
          dostępna 24h/7 dni w tygodniu, a samochód możesz odebrać i zwrócić,
          naszym pracownikom, poza godzinami pracy wypożyczalni.
        </p>
      </>
    ),
  },
  // {
  //   title: "Jak mogę dokonać płatności za wynajęte auto?",
  //   content: () => (
  //     <>
  //       <p></p>
  //     </>
  //   )
  // }
];

const Blah = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  @media (min-width: 991px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
  }
  article {
    margin: 0;
  }
  ul {
    margin-bottom: 0;
  }
`;

const Section2 = styled(Section)`
  padding: 0;
`;

const FaqGdansk = () => {
  return (
    <Section2 id="faq">
      <Container>
        <h2 style={{ marginBottom: "1.5rem", textAlign: "center" }}>
          Najczęściej zadawane pytania
        </h2>
        <JsonLd
          data={{
            "@context": "http://schema.org",
            "@type": "FAQPage",
            mainEntity: [
              ...FAQS.map(({ title, content }) => ({
                "@type": "Question",
                name: title,
                acceptedAnswer: {
                  "@type": "Answer",
                  text: renderToStaticMarkup(content()),
                },
              })),
            ],
          }}
        />
        <Blah>
          {FAQS.map(({ title, content }) => (
            <FaqItem title={title} key={title}>
              {content()}
            </FaqItem>
          ))}
        </Blah>
      </Container>
    </Section2>
  );
};

export default FaqGdansk;
