import React, { useState } from "react";

import Layout from "@common/Layout";
import { TopHeader } from "@common/TopHeader";

import Footer from "@sections/Footer";
import styled from "styled-components";
import { Section, Container } from "@components/global";
import { Button, Checkbox } from "@material-ui/core";
import { Link } from "gatsby";
import {
  StyledSelect,
  StyledTextField,
  StyledFormControlLabel,
  StyledCheckbox,
  CheckboxLabel,
  Label,
} from "@common/Form";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Phone } from "styled-icons/icomoon";

export const TwoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: ${(props) => props.theme.screen.sm}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;
  }
`;

const NextButton = styled(({ className, ...rest }) => {
  return (
    <div className={className}>
      <Button {...rest} />
    </div>
  );
})`
  text-align: center;
  margin-top: 1rem;
`;

const Kontakt = styled.div`
  box-shadow: 0 0 12px 8px rgba(0, 0, 0, 0.08);
  padding: 12px 16px;
  border-radius: 4px;
`;

const Dane = styled.div`
  box-shadow: 0 0 12px 8px rgba(0, 0, 0, 0.08);
  padding: 12px 16px;
  border-radius: 4px;
  & p {
    font-size: 16px;
    line-height: 1.5;
    & strong {
      color: #222;
    }
  }
`;

const Text = styled.article`
  background: ${(props) => props.theme.color.white.dark};
  padding: 1rem;
  & p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 1rem;
    & strong {
      color: #222;
    }
  }
  & p:last-child {
    margin-bottom: 0;
  }
`;

export const ThankYou = styled.div`
  font-family: ${(props) => props.theme.font.secondary};
  ${(props) => props.theme.font_size.large};
  text-align: center;
  margin: 4rem 0;
`;

export const Error = styled.div`
  color: red;
`;

export const Form = styled(({ nameOnly, noCompany, ...rest }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [tos, setTos] = useState(false);
  const [sending, setSending] = useState(false);
  const [done, setDone] = useState(false);

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    if (!name || name.length < 2) {
      if (nameOnly) {
        setError("Proszę podać imię");
      } else {
        setError("Proszę podać imię i nazwisko");
      }
      return;
    }
    if (!phone) {
      setError("Proszę podać numer telefonu");
      return;
    }
    if (phone.length < 9) {
      setError("Proszę podać poprawny, 9-cyfrowy numer telefonu");
      return;
    }
    if (!message || message.length < 4) {
      setError("Proszę napisać wiadomość");
      return;
    }
    if (
      !email ||
      !email.includes("@") ||
      !email.includes(".") ||
      email.length < 5
    ) {
      setError("Proszę podać adres e-mail");
      return;
    }

    if (!tos) {
      setError("Proszę zaakceptować regulamin oraz politykę prywatności");
      return;
    }

    setError("");
    setSending(true);

    const body = {
      name,
      companyName,
      email,
      phone,
      message,
    };

    // console.log(body);
    // setSending(false);
    // return;

    try {
      await fetch(
        "https://us-central1-wypozyczalnia-5c467.cloudfunctions.net/sendContactForm",
        {
          mode: "cors",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      setSending(false);
      setDone(true);
    } catch (err) {
      setSending(false);
      setError("Ups.. coś poszło nie tak");
    }
  };

  return (
    <div {...rest}>
      {done ? (
        <>
          <ThankYou>Dziękujemy za wiadomość.</ThankYou>
        </>
      ) : (
        <>
          <form onSubmit={handleSubmit}>
            <Label>
              {nameOnly ? "Imię *" : "Imię i nazwisko *"}
              <br />
              <StyledTextField
                fullWidth
                variant="outlined"
                type="text"
                onChange={(ev) => {
                  setName(ev.target.value);
                }}
                value={name}
              />
            </Label>
            {noCompany ? null : (
              <Label>
                Nazwa firmy
                <br />
                <StyledTextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  onChange={(ev) => {
                    setCompanyName(ev.target.value);
                  }}
                  value={companyName}
                />
              </Label>
            )}
            <TwoGrid>
              {" "}
              <Label>
                Adres e-mail *
                <br />
                <StyledTextField
                  fullWidth
                  variant="outlined"
                  type="email"
                  onChange={(ev) => {
                    setEmail(ev.target.value);
                  }}
                  value={email}
                />
              </Label>
              <Label>
                Telefon *
                <br />
                <StyledTextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  onChange={(ev) => {
                    setPhone(ev.target.value);
                  }}
                  value={phone}
                />
              </Label>
            </TwoGrid>

            <Label>
              Wiadomość *
              <br />
              <StyledTextField
                fullWidth
                variant="outlined"
                type="text"
                multiline
                rows={4}
                onChange={(ev) => {
                  setMessage(ev.target.value);
                }}
                value={message}
              />
            </Label>

            <CheckboxLabel
              control={
                <Checkbox
                  checked={tos}
                  onChange={(ev) => {
                    const checked = ev.target.checked;
                    setTos(checked);
                  }}
                  color="primary"
                />
              }
              label={
                <>
                  Akceptuję{" "}
                  {/* <a href="/regulamin" target="_blank" rel="noopener">
                    regulamin
                  </a>{" "}
                  oraz{" "} */}
                  <a
                    href="/polityka-prywatnosci"
                    target="_blank"
                    rel="noopener"
                  >
                    politykę prywatności
                  </a>
                  .
                </>
              }
            />

            {error ? <Error>{error}</Error> : null}
            {sending ? (
              <div style={{ textAlign: "center", margin: "1rem" }}>
                <CircularProgress />
              </div>
            ) : (
              <NextButton
                variant="contained"
                color="primary"
                size="large"
                type="submit"
              >
                Wyślij wiadomość
              </NextButton>
            )}
          </form>
        </>
      )}
    </div>
  );
})``;

const GridTop = styled.div`
  display: grid;
  grid-template-areas: "kontakt" "dane";
  grid-gap: 2rem;

  & ${Kontakt} {
    grid-area: kontakt;
  }

  & ${Dane} {
    grid-area: dane;
  }

  @media (min-width: 991px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "kontakt dane";
  }
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const GridBottom = styled.div`
  display: grid;
  grid-template-areas: "form" "text";
  grid-gap: 2rem;

  & ${Text} {
    grid-area: text;
  }

  & ${Form} {
    grid-area: form;
  }

  @media (min-width: 991px) {
    grid-template-columns: 2fr 3fr;
    grid-template-areas: "form text";
  }
  margin-bottom: 2rem;
`;

const PhoneIcon = styled(Phone)`
  fill: #555;
  color: #555;
  transform: scale(0.8);
  width: 36px;
  height: 36px;
  margin: 0 0.5rem;
`;

const Page = () => {
  return (
    <Layout
      seo={{
        url: "https://www.najtansza-wypozyczalnia-samochodow.pl/kontakt",
      }}
    >
      <TopHeader />
      <Container>
        <GridTop>
          <Kontakt>
            <h2>Chcesz zarezerwować samochód?</h2>
            <p style={{ marginTop: "1rem", marginBottom: "2rem" }}>
              Wypełnij <Link to="/">formularz rezerwacji</Link> bądź zadzwoń pod
              numer telefonu:
            </p>
            <p
              style={{
                fontSize: "2rem",
                textAlign: "center",
              }}
            >
              <PhoneIcon />
              <a href="tel:+48883154531">+48 883-154-531</a>
            </p>
          </Kontakt>
          <Dane>
            <h2>Dane rejestracyjne firmy</h2>
            <p style={{ marginTop: "1rem" }}>
              <strong>RENT ME CAR</strong>
              <br />
              Rogalskiego 10
              <br />
              03-982 Warszawa
              <br />
              <strong>Telefon:</strong>{" "}
              <a href="tel:+48883154531">+48 883-154-531</a>
            </p>
          </Dane>
          {/* <Dane>
            <h2>Dane rejestracyjne firmy</h2>
            <p style={{ marginTop: "1rem" }}>
              <strong>Adam Manikowski</strong>
              <br />
              Dworszowice Kościelne Kolonia 31
              <br />
              98-331 Nowa Brzeźnica
              <br />
              <strong>NIP:</strong> 508-006-78-29
              <br />
              <strong>REGON:</strong> 369425363
              <br />
              <strong>Telefon:</strong>{" "}
              <a href="tel:+48883154531">+48 883-154-531</a>
            </p>
          </Dane> */}
        </GridTop>
        <GridBottom>
          <Text>
            <p>
              Witamy Państwa na oficjalnej stronie{" "}
              <strong>Rent Me Car najtańszej wypożyczalni samochodów</strong>.
              Nasza firma działa prężnie od 2018 roku, a do zaproponowania mamy{" "}
              <u>wynajem krótkoterminowy samochodów</u> na terenie Trójmiasta
              oraz wynajem długoterminowy aut na skalę całej Polski. W swojej
              flocie mamy pojazdy takich marek jak: Ford, Skoda, Renault,
              Volkswagen, ale jest ona ciągle rozbudowywana o nowe pojazdy.
              Obsłużyliśmy już setki zadowolonych klientów.
            </p>
            <p>
              Flagowym produktem naszej wypożyczalni samochodów są{" "}
              <strong>pojazdy z instalacją LPG</strong>, co znacznie wyróżnia
              nas na tle konkurencji. Dzięki zastosowaniu sekwencyjnych{" "}
              <a href="https://mamfach.pl/profil/czaslpg">instalacji LPG</a> IV generacji w samochodach benzynowych -{" "}
              <u>koszt przejechania 100 km</u> takim pojazdem potrafi zamknąć
              się w kwocie <u>12 zł</u>. To właśnie sprawia, że całkowity koszt
              najmu, w którego skład wchodzi nie tylko cena za dobę wynajmu
              pojazdu, lecz przecież jeszcze jego utrzymanie, zamyka się bardzo
              niskich stawkach. Wynajmujemy samochody zarówno na krótki, jak i
              długi termin. Realizujemy{" "}
              <u>
                najmy bez kaucji i podstawiamy samochód na wskazany przez
                klienta adres
              </u>
              .
            </p>
            <p>
              Wypożyczamy <strong>pojazd zastępczy z OC sprawcy</strong>.
              Stawiamy zadowolenie klienta na pierwszym miejscu, a dla stałych
              bywalców naszej wypożyczalni oferujemy wysokie rabaty i promocje.
              Odpowiedź na wszelkie nurtujące pytania znajdą Państwo w zakładce
              FAQ. Jeśli jednak odpowiedzi nie znajdą Państwo na stronie służymy
              pomocą mailowo oraz telefonicznie. Zachęcamy do skorzystania z
              zakładki Rezerwacje, bądź do kontaktu telefonicznego z naszym
              Działem Obsługi Klienta. Jesteśmy do Państwa dyspozycji 24h/7 dni
              w tygodniu.
            </p>
          </Text>
          <Form>bla</Form>
        </GridBottom>
      </Container>
      <Footer />
    </Layout>
  );
};

export default Page;
