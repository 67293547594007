import React, { useState } from "react";
import styled from "styled-components";

import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";

import Layout from "@common/Layout";
import { TopHeader } from "@common/TopHeader";
import CarsCarousel from "@common/CarsCarousel";

import HeaderGdansk from "@sections/HeaderGdansk";
import FeaturesGdansk from "@sections/FeaturesGdansk";
import Features from "@sections/Features";
import FaqGdansk from "@sections/FaqGdansk";
import Footer from "@sections/Footer";
import { Section, Container } from "@components/global";

const StandaloneText = styled.div`
  max-width: 991px;
  margin: 0 auto;
  padding: 0 1rem;
  & p {
    margin-bottom: 1rem;
    & strong {
      color: ${(props) => props.theme.color.black.regular};
    }
  }
  margin-bottom: 2rem;
`;

const Karteczka = styled.section`
  position: relative;
  width: 100%;
  height: auto;
  min-height: 250px;

  & > div {
    border-radius: 0.5rem;
    height: 100%;
    min-height: 250px;
    padding: 2rem 1rem;
    & > div {
      border-radius: 0.5rem;
      position: absolute !important;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      min-height: 250px;
      z-index: 1;
      filter: opacity(0.14) saturate(0);
      background: #eaeaea;
    }
    & > h3 {
      position: relative;
      display: block;
      width: 100%;
      margin-bottom: 0.5rem;
      font-size: 18px;
      text-align: center;
      font-family: ${(props) => props.theme.font.primary};
      z-index: 2;
      color: #222;
    }
    & p {
      position: relative;
      font-size: 14px;
      line-height: 1.6;
      z-index: 2;
      color: #222;

      & strong {
        display: inline;
        font-weight: bold;
        color: ${(props) => props.theme.color.black.regular};
      }
    }
  }
`;

const Karteczki = styled(({ data, ...rest }) => {
  const [open, setOpen] = useState(null);
  return (
    <section {...rest}>
      <Karteczka
        open={open === 1}
        onClick={() => {
          setOpen(1);
        }}
      >
        {/* <div>
          <Img
            loading="eager"
            fluid={data.bezKaucji.childImageSharp.fluid}
            alt=""
          />
          <h3>Wypożyczalnia samochodów bez kaucji</h3>
        </div> */}
        <div>
          <Img
            loading="eager"
            fluid={data.bezKaucji.childImageSharp.fluid}
            alt="Wypożyczalnia samochodów Warszawa"
          />
          <Link to="/">
            <h3
              style={{
                display: "block",
                zIndex: 2,
                position: "relative",
              }}
            >
              Wypożyczalnia samochodów Warszawa
            </h3>
          </Link>
          <p>
            Najtańsza wypożyczalnia samochodów z LPG Rent Me Car oferuje również
            wynajem aut w Warszawie. Już dzisiaj możesz przejść do formularza
            składania rezerwacji i wybrać auto dla siebie. Rent Me Car realizuje
            również wynajem długoterminowy aut Warszawa. W celu poznania oferty
            wynajmu długoterminowego skontaktuj się mailowo lub telefonicznie z
            naszą obsługą. W swojej ofercie mamy nowe auta z gazem, dzięki czemu
            znacząco obniżysz koszty utrzymania pojazdu na codzień. Samochód
            podstawiamy na wskazany adres, a koszt kaucji w przypadku wynajmu
            długoterminowego to jeden czynsz.
          </p>
        </div>
      </Karteczka>
      <Karteczka
        open={open === 2}
        onClick={() => {
          setOpen(2);
        }}
      >
        {/* <div>
          <Img
            loading="eager"
            fluid={data.wynajemDlugoterminowy.childImageSharp.fluid}
            alt=""
          />
          <h3>Wynajem długoterminowy samochodów Gdańsk</h3>
        </div> */}
        <div>
          <Img
            loading="eager"
            fluid={data.wynajemDlugoterminowy.childImageSharp.fluid}
            alt="Wynajem długoterminowy samochodów Trójmiasto"
          />
          <h3>Wynajem długoterminowy samochodów Trójmiasto</h3>
          <p>
            Jako wypożyczalnia samochodów oferujemy{" "}
            <b>wynajem długoterminowy aut na terenie całego Trójmiasta</b>.
            Specjalnie dla Państwa przygotowaliśmy bogatą flotę pojazdów, dzięki
            czemu każdy z klientów może dobrać idealne auto na każdą podróż.
            Wynajmujemy samochody bez kaucji i dajemy możliwość wykupienia
            dodatkowego ubezpieczenia, które zwalnia z obowiązku wpłaty kaucji,
            a w razie kolizji czy wypadku, znosi udziału własny w szkodzie. W
            swojej ofercie posiadamy samochody z instalacją LPG. <br />
            Firma RENT ME CAR przygotowała dla Państwa samochody wyposażone w
            sekwencyjne instalację LPG IV generacji, dzięki którym przejechanie
            100 km kosztuje 12 zł. Właśnie to sprawia, że dzięki połączeniu
            niskich cen i najtańszych kosztów eksploatacji pojazdów jesteśmy
            najtańszą wypożyczalnią na rynku ! Jeśli jednak oczekujesz
            samochodów z silnikiem wysokoprężnym, to w naszej flocie też
            znajdziesz coś dla siebie.
          </p>
        </div>
      </Karteczka>
      <Karteczka
        open={open === 3}
        onClick={() => {
          setOpen(3);
        }}
      >
        {/* <div>
          <Img
            loading="eager"
            fluid={data.instalacjaLpg.childImageSharp.fluid}
            alt=""
          />
          <h3>Wypożyczalnia samochodów z LPG Gdańsk</h3>
        </div> */}
        <div>
          <Img
            loading="eager"
            fluid={data.instalacjaLpg.childImageSharp.fluid}
            alt="Wypożyczalnia samochodów z LPG Gdańsk"
          />
          <h3>Wypożyczalnia samochodów z LPG Gdańsk</h3>
          <p>
            Jako jedyni na <b>obszarze Trójmiasta</b> mamy w swojej ofercie{" "}
            <b>auta z instalacją LPG</b>. W znaczący sposób pozwala to{" "}
            <u>obniżyć całkowity koszt</u> związany z wynajęciem pojazdu. Dzięki
            połączeniu niskich stawek za wynajem auta i naszych samochodów z
            <u>sekwencyjnymi instalacjami LPG IV generacji</u>,{" "}
            <b>klient wypożyczalni samochodów uzyskuje najtańsze połączenie</b>{" "}
            przy zachowaniu najwyższej jakości usług.{" "}
            <u>Auta z instalacją LPG</u> to w dzisiejszych czasach najtańsza z
            możliwych opcji korzystania z samochodu (nawet koszt eksploatacji
            pojazdów hybrydowych jest większy od samochodów na gaz). Dodać
            trzeba jeszcze, że producenci mają w zwyczaju mocno zaniżać podawane
            normy spalania samochodów podczas normalnej eksploatacji. Kolejnym
            plusem zastosowanych rozwiązań w naszej wypożyczalni jest zwiększony
            zasięg samochodu. Dzięki czemu po jednej wizycie na stacji paliw,
            możemy przejechać łącznie 1200 km, bądź wybrać opcję ekonomiczną i
            po przejechaniu około 400 km zatankować ponownie samochód gazem do
            pełna.
          </p>
        </div>
      </Karteczka>
    </section>
  );
})`
  display: grid;
  grid-gap: 1rem;
  @media (min-width: 991px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const Frazy = styled.ul`
  color: #222;
  font-weight: 700;
  line-height: 1.5;
  @media (min-width: 500px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 1rem;
  }
`;

const Header = styled.h2`
  margin-top: 0.5rem;
  text-align: center;
`;

const HeaderH4 = styled.h4`
  margin-top: 0.5rem;
  font-size: 26px;
  text-align: center;
`;

const BlahGrid = styled.div`
  display: grid;
  @media (min-width: 1050px) {
    grid-template-columns: 2fr 4fr;
    grid-gap: 1rem;
  }
  @media (min-width: 1200px) {
    grid-template-columns: 2fr 3fr;
  }
  @media (min-width: 1360px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const BlahItem = styled(({ title, children, open, onClick, ...rest }) => {
  return (
    <div {...rest}>
      <div
        className={open ? "faq-title active" : "faq-title"}
        onClick={onClick}
      >
        {title}
      </div>
      <div className={open ? "faq-content active" : "faq-content"}>
        {children}
      </div>
    </div>
  );
})`
  &:hover {
    cursor: pointer;
  }

  & > .faq {
    border-bottom: 2px solid ${(props) => props.theme.color.black.lighter};
  }

  & > .faq-title {
    border: none;
    background: none;
    padding: 0;
    outline: none;
    width: 100%;
    text-align: left;
    cursor: pointer;

    font-family: ${(props) => props.theme.font.secondary};
    ${(props) => props.theme.font_size.small};
    font-weight: bold;
    padding: 24px;
    padding-right: 72px;
    padding-left: 12px;
    position: relative;
    background: #e5e5e5 !important;

    &:hover {
      background: #ddd !important;
    }

    &::after {
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      border-left: 2px solid ${(props) => props.theme.color.black.regular};
      border-bottom: 2px solid ${(props) => props.theme.color.black.regular};
      position: absolute;
      top: 29px;
      right: 36px;
      transform: rotate(-45deg);
      transition: transform 0.3s ease-in-out;
    }

    &.active {
      &::after {
        transform: rotate(135deg);
      }
    }
  }

  & > .faq-content {
    cursor: initial;
    line-height: 26px;
    ${(props) => props.theme.font_size.small};
    color: ${(props) => props.theme.color.black.light};
    height: 0;
    overflow: hidden;
    padding: 0 16px 0px 16px;
    background: #f4f4f4;
    transition: ease-in 0.25s;
    & strong {
      font-weight: bold;
      color: ${(props) => props.theme.color.black.regular};
    }
  }
  & > .faq-content.active {
    height: 380px;
    padding: 16px;
  }
`;

const DesktopSection = styled(Section)`
  display: none;
  @media (min-width: 891px) {
    display: block;
  }
`;

const Blahs = styled(({ ...rest }) => {
  const [open, setOpen] = useState(1);
  return (
    <div {...rest}>
      <BlahItem
        onClick={() => {
          setOpen(1);
        }}
        open={open === 1}
        title="Najtańsza wypożyczalnia samochodów Gdańsk"
      >
        Firma <strong>Rent Me Car</strong> to jedna z{" "}
        <strong>najtańszych wypożyczalni samochodów na terenie Gdańska</strong>.
        Wybierając naszą wypożyczalnię uzyskujesz dostęp do{" "}
        <u>
          floty nowych, ekonomicznych pojazdów z instalacją LPG, bądź z
          oszczędnymi silnikami Diesla
        </u>
        . Łącząc niezwykle <u>niskie ceny za dobę najmu</u> pojazdu oraz koszt
        przejechania 100 km autem z instalacją LPG, dostajesz od nas{" "}
        <strong>najkorzystniejszą dla Ciebie ofertę wynajmu samochodu</strong>.
        Z naszej oferty korzystają zarówno klienci indywidualni, jak i
        biznesowi. Wynajmujemy samochody na dobę, ale i na miesiąc, a nawet rok!
        Zapewniamy najtańszy wynajem aut osobowych w{" "}
        <u>Gdańsku, Trójmieście i na terenie całej Polski</u>. Oferujemy również{" "}
        <strong>
          <u>samochody zastępcze z OC sprawcy</u>
        </strong>{" "}
        dla klientów, którzy brali udział w kolizji lub wypadku komunikacyjnym.
        Opłatę za wynajem rozliczamy bezgotówkowo, a Ty używasz samochodu,
        dopóki Twoje auto nie zostanie przywrócone do stanu przed szkodą. Nie
        obcinamy dni najmu, jak mają w zwyczaju robić to towarzystwa
        ubezpieczeniowe. Flota{" "}
        <strong>
          <u>wypożyczalni samochodów w Gdańsku</u>
        </strong>{" "}
        to nowe auta objęte gwarancją producenta. Dzięki temu uzyskujemy 100%
        pewności, że nasze samochody nie zepsują się podczas zwiedzania Gdańska
        lub dalszej podróży po całej Polsce. Specjalnie dla naszych stałych
        klientów przygotowaliśmy upusty i rabaty, o które podczas rezerwacji
        samochodu, zapytaj nasz Dział Obsługi Klienta!
      </BlahItem>
      <BlahItem
        onClick={() => {
          setOpen(2);
        }}
        open={open === 2}
        title="Wypożyczalnia samochodów Dworzec Główny PKP Gdańsk"
      >
        Jeśli masz w planach przyjazd do Gdańska korzystając z usług PKP, a na
        miejscu będziesz potrzebował <u>samochodu na wynajem</u>, to nasza{" "}
        <strong>
          wypożyczalnia samochodów na Dworcu Głównym PKP w Gdańsku
        </strong>{" "}
        jest idealnym rozwiązaniem na wszystkie Twoje potrzeby. Zarezerwuj
        samochód za pomocą formularza rezerwacji, a my podstawimy dla Ciebie
        auto na Dworzec Główny PKP w Gdańsku, skąd ruszysz w dalszą podróż po
        trójmiejskich drogach. Po złożeniu rezerwacji poprzez naszą stronę
        internetową nasz pracownik skontaktuje się z Tobą, aby potwierdzić
        rezerwację i ustalić szczegóły najmu. Dworzec Główny PKP jest położony w
        samym centrum Gdańska. Niezależnie czy planujesz zwiedzać Starówkę,
        Stocznię, czy Muzeum Solidarności z Dworca Głównego PKP w Gdańsku
        wszędzie będziesz miał blisko. Jest to idealne miejsce odbiór pojazdu,
        jak i jego zwrot.{" "}
        <strong>
          Nasza wypożyczalnia samochodów, gwarantuje podstawienie auta na
          Dworzec Główny PKP całkowicie za darmo!
        </strong>
      </BlahItem>
      <BlahItem
        onClick={() => {
          setOpen(3);
        }}
        open={open === 3}
        title="Wypożyczalnia samochodów Gdańsk lotnisko Rębiechowo im. Lecha Wałęsy"
      >
        <strong>Wypożyczalnia samochodów Gdańsk lotnisko Rębiechowo</strong>{" "}
        podstawia samochody na teren lotniska zupełnie <u>za darmo</u>! Gdańskie
        lotnisko każdego dnia obsługuje setki pasażerów podróżujących do różnych
        krajów, jak i przylatujących do nadmorskiej stolicy. Właśnie dlatego
        firma <strong>Rent Me Car</strong> w celu zapewnienia najlepszej jakości
        świadczonych usług realizuje wynajem samochodów na terenie lotniska.
        Jedyne co musisz zrobić to wybrać termin najmu oraz auto dobrane do
        własnych potrzeb, a my zajmiemy się resztą. Podstawiamy i odbieramy
        samochody całą dobę, 7 dni w tygodniu. W swojej ofercie pojazdów mamy{" "}
        <u>samochody z instalacją LPG</u>, co sprawia, że koszty eksploatacji
        pojazdu znacznie maleją. Przed każdorazowym wypożyczeniem samochodu
        sprawdzamy jego stan techniczny i czystość. Dbamy o wysoką jakość
        naszych usług, dlatego dajemy Państwu możliwość swobodnej konfiguracji
        najmu pojazdu poprzez doposażenie samochodu w nawigację GPS, fotelik dla
        dziecka, a także możliwość zwrotu zabrudzonego pojazdu.{" "}
        <u>Oferujemy zniżki i rabaty dla stałych klientów</u>. Zachęcamy do
        wypełnienia formularza rezerwacji, jak i do kontaktu telefonicznego z
        naszym Działem Obsługi Klienta.
      </BlahItem>
    </div>
  );
})`
  background: #efefef;
`;

const TextImgGrid = styled.div`
  display: grid;
  grid-gap: 1rem;
  @media (min-width: 991px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const GdanskPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      file(sourceInstanceName: { eq: "other" }, name: { eq: "doh2" }) {
        childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      bezKaucji: file(
        sourceInstanceName: { eq: "other" }
        name: { eq: "bez-kaucji" }
      ) {
        childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      wynajemDlugoterminowy: file(
        sourceInstanceName: { eq: "other" }
        name: { eq: "wynajem-dlugoterminowy" }
      ) {
        childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      instalacjaLpg: file(
        sourceInstanceName: { eq: "other" }
        name: { eq: "instalacja-lpg" }
      ) {
        childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gdansk1: file(
        sourceInstanceName: { eq: "carousel" }
        name: { eq: "gdansk-1" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gdansk2: file(
        sourceInstanceName: { eq: "carousel" }
        name: { eq: "gdansk-2" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gdansk3: file(
        sourceInstanceName: { eq: "carousel" }
        name: { eq: "gdansk-3" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout
      seo={{
        url: "https://www.najtansza-wypozyczalnia-samochodow.pl/wypozyczalnia-samochodow-gdansk",
        title: `Najtańsza wypożyczalnia samochodów Gdańsk. Wynajem długoterminowy aut z LPG 🚗`,
        description: `Najtańsza wypożyczalnia samochodów Gdańsk🚙 Wynajem długoterminowy samochodów z LPG od 46zł/doba. Zadzwoń teraz 883-154-531📞 ! Bez kaucji !`,
      }}
    >
      <TopHeader />
      <HeaderGdansk
        state={location && location.state ? location.state : null}
      />
      <Features />

      <Section
        style={{ paddingTop: 0, marginTop: "3rem", marginBottom: "-2rem" }}
      >
        <Container fluid>
          <TextImgGrid>
            <div>
              <Header style={{ marginBottom: "1.5rem" }}>
                Wynajem długoterminowy samochodów Gdańsk
              </Header>
              <p>
                Wynajem długoterminowy samochodów zaczyna się, kiedy klient chce
                wypożyczyć samochód na okres <b>dłuższy niż 12 miesięcy</b>.
                Jest to rodzaj usługi podobny do leasingu, lecz ma kilka
                dodatkowych plusów. W momencie wynajmu długoterminowego auta
                koszty związane z jego eksploatacją są po stronie wypożyczalni.
                Dzięki naszej bogatej flocie samochodów, wybór odpowiedniego
                modelu dostosowanego do swoich potrzeb jest bardzo prosty.
                Miesięczny koszt najmu określa się na podstawie kilku
                informacji,{" "}
                <b>których najtańsza wypożyczalnia samochodów Rent Me Car</b>{" "}
                potrzebuje od Klienta. Jest to okres{" "}
                <u>
                  najmu pojazdu, model samochodu, limit kilometrów, liczba
                  kierowców uprawnionych do kierowaniem auta, wiek kierowców
                  oraz rodzaj wykorzystywania samochodu
                </u>
                . Jeśli jesteś zainteresowany naszą ofertą, skontaktuj się z{" "}
                <u>Działem Obsługi Klienta</u>, który pomoże dobrać idealną
                ofertę do twoich potrzeb.
              </p>
            </div>
            <Img
              fluid={data.gdansk1.childImageSharp.fluid}
              alt="Wynajem samochodów Gdańsk"
              style={{
                width: "100%",
                minHeight: "300px",
                height: "100%",
              }}
            />
          </TextImgGrid>
        </Container>
      </Section>

      <DesktopSection>
        <Container>
          <Header>
            <Link to="/samochody">Nasze samochody</Link>
          </Header>
        </Container>
        <CarsCarousel />
      </DesktopSection>

      <Section style={{ marginTop: "-1rem", marginBottom: "-1rem" }}>
        <Container fluid>
          <StandaloneText>
            <Header style={{ marginBottom: "1.5rem" }}>
              Tani wynajem długoterminowy samochodów Gdańsk Lotnisko oraz
              Dworzec PKP
            </Header>

            <p>
              <b>
                Wypożyczalnia samochodów Gdańsk Rent Me Car oferuje tani wynajem
                długoterminowy samochodów
              </b>{" "}
              wraz z podstawieniem na <b>Lotnisko Gdańsk Rembiechowo</b> oraz{" "}
              <b>Dworzec Główny PKP Gdańsk</b>. Podstawienie samochodu we
              wskazane wyżej miejsca jest zupełnie darmowe, a Ty nie musisz
              martwić się dodatkowymi problemami związanych z wynajmem auta.
            </p>
            <p>
              Jeśli przylatujesz do Gdańska, wystarczy że wypełnisz formularz
              kontaktowy, a w zakładce uwagi dodatkowe dopiszesz numer lotu.
              Pracownik <b>Rent Me Car</b> będzie czekał na{" "}
              <b>Lotnisku Gdańsk Rembiechowo</b> z autem i umową najmu pojazdu
              gotową do podpisania. Jeśli przyjeżdżasz do Gdańska za pomocą PKP,
              również podaj nam stacje docelową Twojego pociągu, aby ułatwić
              odnalezienie siebie na <b>Dworcu Głównym PKP Gdańsk</b>.
            </p>
            <p>
              <b>Tani wynajem samochodów Gdańsk</b> to nie tylko gwarancja
              jakości świadczonych usług przez naszą firmę, ale również pewność,
              że koszty związane z wynajmem długoterminowym samochodu są jak
              najniższe. Dodając do tego podstawienie samochodu na terenie
              Lotniska Rembiechowo lub Dworca Głównego w Gdańsku zupełnie za
              darmo, otrzymujecie Państwo pakiet usług z najwyższej półki.
            </p>
            <p>
              Serdecznie zapraszamy do zapoznania się ofertą w zakładce
              <Link to="/samochody">Samochody</Link>, gdzie sprawdzą Państwo
              dostępne samochody w firmę{" "}
              <b>
                Rent Me Car Wypożyczalnia samochodów Gdańsk. Tania wypożyczalnia
                samochodów
              </b>{" "}
              to nie tylko cena za dobę najmu auta, ale również koszty dodatków,
              które mogą Państwo dobrać do wynajętego pojazdu. Warto zwrócić
              uwagę na wykup dodatkowego ubezpieczenia, które znosi udział w
              szkodzie podczas kolizji czy wypadku. Realizując{" "}
              <b>tani wynajem aut Gdańsk</b>, my, jako rzetelna{" "}
              <b>wypożyczalnia samochodów Gdańsk</b>, staramy się stawiać
              Klienta na pierwszym miejscu! Serdecznie zapraszamy do zapoznania
              się ofertą w zakładce{" "}
            </p>
          </StandaloneText>
        </Container>
      </Section>

      <Section style={{ paddingTop: 0, marginTop: "0", marginBottom: "-2rem" }}>
        <Container fluid>
          <TextImgGrid>
            <Img
              fluid={data.gdansk2.childImageSharp.fluid}
              alt="Najtańsza wypożyczalnia samochodów Gdańsk"
              style={{
                width: "100%",
                minHeight: "300px",
                height: "100%",
              }}
            />
            <div>
              <Header style={{ marginBottom: "1.5rem" }}>
                Najtańszy wynajem długoterminowy aut z LPG Gdańsk
              </Header>

              <p>
                Firma <b>Rent Me Car</b> to jedna z{" "}
                <b>najtańszych wypożyczalni samochodów na terenie Gdańska</b>.
                Wybierając naszą wypożyczalnię uzyskujesz dostęp do{" "}
                <u>
                  floty nowych, ekonomicznych pojazdów z instalacją LPG, bądź z
                  oszczędnymi silnikami Diesla
                </u>
                . Łącząc niezwykle <u>niskie ceny za dobę najmu</u> pojazdu oraz
                koszt przejechania 100 km autem z instalacją LPG, dostajesz od
                nas{" "}
                <b>
                  najkorzystniejszą dla Ciebie ofertę wynajmu długoterminowego
                  samochodu
                </b>
                . Z naszej oferty korzystają zarówno klienci indywidualni, jak i
                biznesowi. Wynajmujemy samochody na minimum dwa miesiące, a
                nawet lata! Zapewniamy najtańszy wynajem długoterminowy aut
                osobowych w{" "}
                <u>Gdańsku, Trójmieście i na terenie całej Polski</u>. Flota{" "}
                <u>
                  <b>wypożyczalni samochodów w Gdańsku</b>
                </u>{" "}
                to nowe auta objęte gwarancją producenta. Dzięki temu uzyskujemy
                100% pewności, że nasze samochody nie zepsują się podczas
                zwiedzania Gdańska lub dalszej podróży po całej Polsce.
                Specjalnie dla naszych stałych klientów przygotowaliśmy upusty i
                rabaty, o które podczas rezerwacji samochodu, zapytaj nasz Dział
                Obsługi Klienta!
              </p>
            </div>
          </TextImgGrid>
        </Container>
      </Section>

      <Section
        style={{ paddingTop: 0, marginTop: "3rem", marginBottom: "-2rem" }}
      >
        <Container fluid>
          <TextImgGrid>
            <div>
              <Header style={{ marginBottom: "1.5rem" }}>
                Wynajem długoterminowy aut Dworzec Główny PKP Gdańsk
              </Header>
              <p>
                Jeśli masz w planach przyjazd do Gdańska korzystając z usług
                PKP, a na miejscu będziesz potrzebował{" "}
                <u>samochodu na wynajem</u>, to nasza{" "}
                <b>wypożyczalnia samochodów na Dworcu Głównym PKP w Gdańsku</b>{" "}
                jest idealnym rozwiązaniem na wszystkie Twoje potrzeby.
                Zarezerwuj samochód za pomocą formularza kontaktowego, a my
                podstawimy dla Ciebie auto na Dworzec Główny PKP w Gdańsku, skąd
                ruszysz w dalszą podróż po trójmiejskich drogach. Po złożeniu
                zapytania poprzez naszą stronę internetową nasz pracownik
                skontaktuje się z Tobą, aby potwierdzić rezerwację i ustalić
                szczegóły najmu długoterminowego. Dworzec Główny PKP jest
                położony w samym centrum Gdańska. Niezależnie czy planujesz
                zwiedzać Starówkę, Stocznię, czy Muzeum Solidarności z Dworca
                Głównego PKP w Gdańsku wszędzie będziesz miał blisko. Jest to
                idealne miejsce odbiór pojazdu, jak i jego zwrot.{" "}
                <b>
                  Nasza wypożyczalnia samochodów, gwarantuje podstawienie auta
                  na Dworzec Główny PKP całkowicie za darmo!
                </b>
              </p>
            </div>
            <Img
              fluid={data.gdansk3.childImageSharp.fluid}
              alt="Wypożyczalnia samochodów Dworzec Główny PKP Gdańsk"
              style={{
                width: "100%",
                minHeight: "300px",
                height: "100%",
              }}
            />
          </TextImgGrid>
        </Container>
      </Section>

      <Section
        style={{ paddingTop: 0, marginTop: "3rem", marginBottom: "1rem" }}
      >
        <Container fluid>
          <TextImgGrid>
            <Img
              fluid={data.gdansk3.childImageSharp.fluid}
              alt="Wypożyczalnia samochodów Gdańsk lotnisko Rębiechowo im. Lecha Wałęsy"
              style={{
                width: "100%",
                minHeight: "300px",
                height: "100%",
              }}
            />
            <div>
              <Header style={{ marginBottom: "1.5rem" }}>
                Wynajem długoterminowy samochodu Gdańsk lotnisko Rębiechowo im.
                Lecha Wałęsy
              </Header>
              <p>
                <b>Wypożyczalnia samochodów Gdańsk lotnisko Rębiechowo</b>{" "}
                podstawia samochody na teren lotniska zupełnie <u>za darmo</u>!
                Gdańskie lotnisko każdego dnia obsługuje setki pasażerów
                podróżujących do różnych krajów, jak i przylatujących do
                nadmorskiej stolicy. Właśnie dlatego firma <b>Rent Me Car</b> w
                celu zapewnienia najlepszej jakości świadczonych usług realizuje
                wynajem długoterminowy samochodów na terenie lotniska. Jedyne co
                musisz zrobić to wybrać termin najmu oraz auto dobrane do
                własnych potrzeb, a my zajmiemy się resztą. Podstawiamy i
                odbieramy samochody całą dobę, 7 dni w tygodniu. W swojej
                ofercie pojazdów mamy samochody z instalacją LPG, co sprawia, że
                koszty eksploatacji pojazdu znacznie maleją. Przed każdorazowym
                wypożyczeniem samochodu sprawdzamy jego stan techniczny i
                czystość. Dbamy o wysoką jakość naszych usług, dlatego dajemy
                Państwu możliwość swobodnej konfiguracji najmu pojazdu poprzez
                doposażenie samochodu w nawigację GPS, fotelik dla dziecka, a
                także możliwość zwrotu zabrudzonego pojazdu.{" "}
                <u>Oferujemy zniżki i rabaty dla stałych klientów</u>. Zachęcamy
                do wypełnienia formularza rezerwacji, jak i do kontaktu
                telefonicznego z naszym Działem Obsługi Klienta.
              </p>
            </div>
          </TextImgGrid>
        </Container>
      </Section>

      <FeaturesGdansk />
      <Section>
        <Container fluid>
          <Header style={{ marginBottom: "1.5rem" }}>
            Tani wynajem aut w Gdańsku
          </Header>
          <StandaloneText>
            <p>
              Firma <strong>RENT ME CAR</strong> oferuje zarówno krótkoterminowy
              najem pojazdów oraz długoterminowy, w przypadku którego
              klient-najemca opłaca ustaloną przed podpisaniem umowy{" "}
              <u>
                stałą opłatę miesięczną (niezmienną przez cały okres trwania
                umowy)
              </u>
              . Opłatę tę można doliczyć do kosztów uzyskania dochodu, a{" "}
              <strong>faktura za wynajem zawiera kwotę 23% podatku VAT</strong>.
              Po stronie Klienta pozostaje tylko tankowanie i użytkowanie
              samochodu. Koszt paliwa, również można uwzględnić w kosztach
              uzyskania dochodu.{" "}
              <strong>Wypożyczalnia aut Gdańsk Rent Me Car</strong>{" "}
              <u>
                ponosi wszelkie opłaty związane z naprawami samochodu, kosztami
                przeglądów, obowiązkowym ubezpieczeniem OC oraz zapewnia opony
                letnie w sezonie od 1 marca do 31 listopada, a w pozostałej
                części roku kalendarzowego opony zimowe
              </u>
              .
            </p>
            <p>
              Klient nie musi posiadać wkładu własnego, a wynajem nie jest
              traktowany jako obciążenie kredytowe. Firma Rent Me Car oferuje
              tani wynajem długoterminowy samochodów wszystkich klas na okres
              nawet 6 lat! Nasza zróżnicowana oferta pozwala dobrać auto idealne
              do potrzeb każdego klienta. Zaczynając od kompaktowego Volkswagena
              Up, który pozwoli w łatwy i wygodny sposób poruszać Ci się po
              trójmiejskich drogach, poprzez wygodną i elegancką{" "}
              <strong>Skodę Superb</strong> z automatyczną skrzynią biegów,
              która zapewni Ci komfortową podróż po całej Europie, a kończąc na
              jednodniowej przygodzie z <strong>Fordem Mustangiem GT</strong>{" "}
              5.0 z 450 koniami pod maską. Skorzystaj z naszych usług i zadzwoń
              pod numer kontaktowy, a my udzielimy odpowiedzi na wszystkie Twoje
              pytania! Dla naszej wypożyczalni najważniejsze jest{" "}
              <strong>dobro Klienta</strong>, które zawsze stawia na pierwszym
              miejscu. Lecz także jakość oraz odpowiednia ocenę za świadczoną
              usługę.
            </p>
          </StandaloneText>
          <Karteczki data={data} />
        </Container>
      </Section>

      <Section fluid>
        <Header style={{ marginTop: "0", marginBottom: "1.5rem" }}>
          Wynajem długoterminowy auta z LPG Trójmiasto bez kaucji
        </Header>
        <StandaloneText>
          <p>
            Wybrałeś termin najmu oraz samochód, ale nie chcesz wpłacać kaucji?
            Firma Rent Me Car daje możliwość wypożyczenia <b>auta bez kaucji</b>
            ! Jest to możliwe jeśli klient zdecyduje się na wykupienie
            <b>dodatkowego ubezpieczenia</b>, które znosi jego udział własny w
            szkodzie - w momencie nieumyślnego spowodowania kolizji lub wypadku.
            Dodatkowe ubezpieczenie, nie dość, że daje możliwość wynajmu
            samochodu bez uiszczania depozytu, to dodatkowo chroni Cię przed
            nieszczęśliwymi wypadkami czy to na drodze, czy na parkingu.
            Ubezpieczenie chroni Cię przed uszkodzeniami opon, karoserii oraz
            szyb. Podczas wynajmu pojazdu to Ty odpowiadasz za samochód, a
            ubezpieczenie znosi Twój udział w szkodzie. Kwota ubezpieczenia jest
            zależna od segmentu pojazdu oraz nie obejmuje samochodów z segmentu
            SPORT PREMIUM. Wykup dodatkowego ubezpieczenia pojazdów to jedno z
            najczęściej wybieranych udogodnień przez klientów wypożyczalni
            samochodów Warszawa, które umożliwia wynajem samochodu bez kaucji.
            Decyzja należy do Ciebie!
          </p>
        </StandaloneText>
      </Section>

      <Section fluid>
        <Header style={{ marginTop: "0", marginBottom: "1.5rem" }}>
          RENT ME CAR Najtańsza wypożyczalnia samochodów Gdańsk
        </Header>
        <StandaloneText>
          <p>
            Jako wypożyczalnia samochodów realizujemy nie tylko najmy aut, ale
            zajmujemy się również wieloma usługami związanymi z samochodami.
            Jeśli potrzebujesz pojazdu zastępczego z OC sprawcy bądź autocasco
            to trafiłeś w odpowiednie ręce! W momencie kolizji bądź wypadku
            drogowego, gdy jesteś poszkodowanym należy Ci się auto zastępcze z
            OC sprawcy. Zadzwoń do nas, a my nie tylko podstawimy Ci samochód
            pod dom, ale pomożemy w dochodzeniu odszkodowania z towarzystwem
            ubezpieczeniowym. Ubezpieczyciele potrafią zaniżać kwoty odszkodowań
            z wypadków komunikacyjnych nawet o 100%, a wynajem pojazdów
            zastępczych z ich wypożyczalni często trwa dużo krócej. My zajmujemy
            się tym bezgotówkowo. Wybierając pojazd zastępczy z naszej
            wypożyczalni, będziesz jeździł nim do czasu, aż Twoje auto nie
            zostanie naprawione, a my rozliczymy się z ubezpieczycielem bez
            Twojego udziału.
          </p>
          <p>
            Dla wszystkich, którzy nie posiadają prawa jazdy bądź boją się
            jeździć po Trójmieście przygotowaliśmy specjalną ofertę! Wynajmujemy
            również samochody z kierowcą. To wygodne rozwiązanie podczas
            planowania spotkań biznesowych, imprez rodzinnych oraz podróży
            służbowych. Nasi kierowcy to ludzie z wieloletnim stażem za
            kierownicą taksówek. Nie tylko jeżdżą bezpiecznie, ale polecą Ci
            również ciekawe miejsca w Trójmieście oraz pomogą wybrać restaurację
            na kolację. A kiedy brakuje czasu znajdą odpowiedni skrót, aby
            ominąć korek. Jeśli zaciekawiła Cię nasza oferta to zachęcamy do
            skorzystania z formularza REZERWACJE, za pomocą którego podasz
            wszystkie potrzebne informacje do wynajęcia samochodu. Rezerwacja
            online jest prosta oraz znacznie usprawnia proces najmu pojazdu.
            Jeśli jednak masz jakieś wątpliwości - zachęcamy do skorzystania z
            zakładki FAQ, gdzie przygotowaliśmy odpowiedzi na najczęściej
            zadawane przez naszych klientów pytania. Wolisz porozmawiać z nami
            osobiście? Nie czekaj i zadzwoń do nas, a my odpowiemy na wszystkie
            Twoje pytania. Nasz Dział Obsługi Klienta służy pomocą całą dobę
            przez 7 dni w tygodniu.
          </p>
        </StandaloneText>
      </Section>

      <FaqGdansk />

      <Section fluid>
        <HeaderH4 style={{ marginBottom: "1.5rem" }}>
          RENT ME CAR Gdańsk
        </HeaderH4>
        <StandaloneText>
          <p>
            Oddział firmy RENT ME CAR w Gdańsku znajduje się w samym centrum
            Gdańska-stolicy pomorza oraz jednego z trzech miast wchodzących w
            skład Trójmiasta.
          </p>
          <p>
            To miasto, które przyciąga ogrom turystów z Polski i zagranicy,
            zarówno latem jak i zimą. Gdańska plaża w Brzeźnie oraz Jelitkowie
            to jedne z najpiękniejszych miejsc w Polsce. Niestety poruszanie się
            po Trójmieście komunikacją miejską nie jest najlepszym rozwiązaniem,
            ponieważ wymaga to czasu, którego na wakacjach nie może brakować.
            Właśnie tutaj pojawia się wypożyczalnia samochodów RENT ME CAR,
            która wychodzi naprzeciw oczekiwaniom klientów i oferuje tani
            wynajem aut na terenie każdego z trzech miast: Sopot, Gdynia oraz
            Gdańsk. Dzięki naszym samochodom, będą Państwo mogli zwiedzić
            wszystkie zakamarki każdego miasta, a nawet wybrać się w dalszą
            podróż. Wycieczka do Władysławowa lub półwysep Helski nie będzie
            nikomu straszna z wypożyczonym samochodem.
          </p>
          <p>
            W Gdańsku można zwiedzić wiele zabytków takich jak Westerplatte,
            Stare Miasto czy najpiękniejszą ulicę Mariacką. Najczęściej
            odwiedzanym miejscem przez przyjezdnych jest Molo w Brzeźnie,
            Fontanna Neptuna oraz ulica Długa w samym centrum Starego Miasta.
            Gdańsk to miasto bursztynu, pięknej architektury i nadmorskiej
            bryzy. Zwiedzając Gdańską starówkę koniecznie trzeba zobaczyć ją z
            góry, na co pozwala nam Diabelski Młyn. Sercem Gdańska, w
            odróżnieniu do innych kultowych miast Polski, nie jest stary rynek,
            lecz Długi Targ, który to w XIII wieku był traktem kupieckim.
            Turyści najczęściej robią zdjęcia na Zielonym Moście oraz przy
            Baszcie Łabędź. Nie można również pominąć Muzeum Morskiego, które
            usytuowane jest po drugiej stronie Motławy. Jeśli zaś chcemy
            zobaczyć pełną panoramę Gdańska, musimy wybrać się na Pachołek. Jest
            to najwyżej położony punkt widokowy w stolicy Trójmiasta. Przy
            okazji oglądania Gdańska z góry, następną pobliską atrakcją jest
            Park Oliwski oraz ZOO znajdujące się w tej dzielnicy Gdańska.
            Miłośnicy militariów nie mogą przejść obojętnie koło twierdzy
            gdańskiej oraz twierdzy Wisłoujście, gdzie co roku organizowane są
            imprezy kulturalne. Historycy zaś koniecznie muszą odwiedzić muzeum
            Solidarności oraz Muzeum II Wojny Światowej na które polecamy
            zarezerwować sobie cały dzień.
          </p>
          <p>
            Wypożyczenie samochodu ułatwi Państwu dostęp do tych wszystkich
            miejsc. Jeśli zaliczyliśmy już wszystkie z wymienionych wyżej
            atrakcji, czas wybrać się w dalszą podróż po Trójmiejskich miastach.
            Najbliżej z Gdańska będzie do Sopotu. Miasto to jest z jednej strony
            otoczone złocistymi plażami Gdańskiej Zatoki, a z drugiej
            rozprzestrzenia się Trójmiejski Park Krajobrazowy. Turyści są tutaj
            najczęściej latem, kiedy to temperatura wybija ponad trzydzieści
            kresek. Samo centrum Sopotu przecina znana ulica Bohaterów Monte
            Cassino, częściej znana jako Monciak. Jest to główny deptak
            nadmorskiego kurortu, gdzie mogą poruszać się tylko piesi. Każdy
            znajdzie tutaj coś dla siebie, zaczynając od licznych pubów,
            najlepszych restauracji, a na Krzywym Domku kończąc. W Sopocie
            koniecznie zobaczyć trzeba molo oraz Górę Zamkową, gdzie znajduje
            się najstarszy zabytek tego miasta. Dla rodzin z dziećmi polecamy
            Aqua Park. Ostatnim, lecz najmłodszym miastem jest Gdynia, która
            pełni rolę prężnie rozwijającego się miasta portowego, łączącym w
            sobie funkcje handlowe, widokowe oraz rozrywkowe. Zwiedzając Gdynie
            polecamy zacząć od Skweru Kościuszki, nieopodal którego znajdziemy
            przycumowane jednostki pływające, pełniące rolę muzeów.
          </p>
        </StandaloneText>
      </Section>

      <Container>
        <Frazy>
          <li>Wypożyczalnia samochodów Gdańsk</li>
          <li>Wypożyczalnia samochodów Gdańsk dworzec</li>
          <li>Wypożyczalnia samochodów Gdańsk lotnisko</li>
          <li>Tania wypożyczalnia samochodów Gdańsk</li>
          <li>Wynajem samochodów Gdańsk</li>
          <li>Wynajem aut Gdańsk</li>
          <li>Tani wynajem aut Gdańsk</li>
          <li>Wynajem aut Gdańsk lotnisko</li>
          <li>Wynajem długoterminowy samochodów Gdańsk</li>
          <li>Najtańsza wypożyczalnia samochodów Gdańsk</li>
          <li>Wypożyczalnia samochodów z LPG Gdańsk</li>
          <li>Wypożyczalnia samochodów bez kaucji Gdańsk</li>
        </Frazy>
      </Container>
      <Footer />
    </Layout>
  );
};

export default GdanskPage;
