import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import "react-multi-carousel/lib/styles.css";

import { Container, Section } from "@components/global";
import ReservationBox from "@components/common/ReservationBox";
import CarsCarousel from "@common/CarsCarousel";
import { Form } from "../../pages/kontakt";

const Header = styled.h2`
  margin-top: 1rem;
  text-align: center;
`;

export const WrapperForm = styled.div`
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.color.white.dark};
  border-radius: 4px;
  box-shadow: 0 2px 16px 4px rgba(0, 0, 0, 0.16);
  ${(props) => props.theme.font_size.small};
  color: #222;
`;
export const InnerWrapperForm = styled.div`
  padding: 15px 20px;
  width: 100%;
  height: 100%;
  margin: 0 auto;

  @media (min-width: 330px) {
    max-width: 400px;
  }
  @media (min-width: 500px) {
    max-width: 450px;
  }
  @media (min-width: 767px) {
    max-width: 550px;
  }
  @media (min-width: 850px) {
    max-width: initial;
    padding: 20px 20px;
  }
`;

export const HeaderForm = styled.strong`
  font-family: ${(props) => props.theme.font.primary};
  ${(props) => props.theme.font_size.regular};
  display: block;
  margin-top: -0.25rem;
  margin-bottom: 1rem;
`;

const HeaderGdansk = ({ state }) => {
  const data = useStaticQuery(graphql`
    query {
      allImageSharp {
        edges {
          node {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `);
  return (
    <HeaderWrapper>
      <SlideImg
        fluid={
          data.allImageSharp.edges.find((element) => {
            // Match string after final slash
            return element.node.fluid.src.split("/").pop().includes("gdansk-2");
          }).node.fluid
        }
      />
      <PadlessContainer>
        <Grid>
          <SlideBox>
            <SlideTitleH1>Wypożyczalnia samochodów Gdańsk</SlideTitleH1>
            <SlideContent>
              <b>Wypożyczalnia samochodów Rent Me Car na terenie Gdańska </b>
              realizuje <b>wynajem długoterminowy samochodów</b>. Minimalny czas
              najmu auta to dwa miesiące. Do zaoferowania mamy{" "}
              <b>samochody z instalacją LPG</b>, dzięki czemu oprócz niskiej
              ceny za wynajem pojazdu, oszczędzasz na eksploatacji{" "}
              <u>koszt przejechania 100km to jedyne 12 zł</u>. Ty decydujesz
              jaki samochód wybierasz, a my podstawiamy go na{" "}
              <b>wskazany adres</b>.
            </SlideContent>
          </SlideBox>
          <ReservationBoxWrapper>
            <WrapperForm>
              <InnerWrapperForm>
                <HeaderForm>Wynajem długoterminowy samochodu Gdańsk</HeaderForm>
                <Form nameOnly noCompany />
              </InnerWrapperForm>
            </WrapperForm>
          </ReservationBoxWrapper>
          <SlideContent3>
            <Header>
              <Link to="/samochody">Nasze samochody</Link>
            </Header>
            <CarsCarousel2 />
          </SlideContent3>
          <SlideContent2>
            Witamy na stronie{" "}
            <strong>wypożyczalni samochodów Rent me car</strong>! Nasza firma
            specjalizuje się zarówno w{" "}
            <strong>
              krótko jak i długoterminowym wynajmie samochodów osobowych
            </strong>
            . Dzięki naszej szerokiej gamie pojazdów dobierzesz idealny samochód
            do swoich potrzeb. Do zaoferowania mamy również{" "}
            <strong>samochody z instalacją LPG</strong>, dzięki czemu oprócz
            niskiej ceny za wynajem pojazdu,{" "}
            <u>koszt przejechania 100km to jedyne 12 zł</u>. Ty decydujesz jaki
            samochód wybierasz, a my podstawiamy go za darmo na{" "}
            <strong>Port Lotniczy Gdańsk im. Lecha Wałęsy</strong> lub{" "}
            <strong>Dworzec Główny PKP w Gdańsku</strong>. Ponadto możemy
            zaproponować Ci dokupienie <strong>ubezpieczenia</strong> do
            wynajętego pojazdu.
          </SlideContent2>
        </Grid>
      </PadlessContainer>
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.header`
  background-color: ${(props) => props.theme.color.white};
  color: #222;
  /* margin-top: 70px; */
  position: relative;
  @media (min-width: 891px) {
    height: 500px;
  }

  @media (max-width: ${(props) => props.theme.screen.md}) {
    /* margin-top: 128px; */
  }
`;

const SlideImg = styled(Img)`
  max-height: 600px;
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const SlideBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: stretch;
  grid-area: text;
  @media (min-width: 891px) {
    max-width: 500px;
    justify-content: flex-start;
  }
`;

const SlideTitleH1 = styled.h1`
  font-family: ${(props) => props.theme.font.primary};
  ${(props) => props.theme.font_size.large};
  z-index: 5;
  background: ${(props) => props.theme.color.black.regular};
  color: white;
  padding: 10px 15px;
  width: 100%;
  text-align: center;

  svg {
    color: white;
    fill: white;
    width: 32px;
    height: 32px;
    margin-right: 10px;
  }

  @media (min-width: 891px) {
    border-radius: 4px;
    ${(props) => props.theme.font_size.xlarge};
  }
`;

const SlideContent = styled.p`
  font-family: ${(props) => props.theme.font.secondary};
  ${(props) => props.theme.font_size.xsmall};
  z-index: 1050;
  background: ${(props) => props.theme.color.white.dark};
  color: #222;
  display: none;
  line-height: 1.6;
  width: 100%;
  & strong {
    display: inline;
    font-weight: bold;
    color: ${(props) => props.theme.color.black.regular};
  }
  padding: 0.5rem;

  @media (min-width: 891px) {
    padding: 1rem 2rem;
    max-width: 500px;
    margin: 20px 0;
    border-radius: 4px;
    display: block;
  }
`;

const SlideContent2 = styled.p`
  font-family: ${(props) => props.theme.font.secondary};
  ${(props) => props.theme.font_size.xsmall};
  z-index: 1050;
  background: ${(props) => props.theme.color.white.dark};
  color: #222;
  display: block;
  line-height: 1.6;
  width: 100%;
  & strong {
    display: inline;
    font-weight: bold;
    color: ${(props) => props.theme.color.black.regular};
  }
  padding: 0.5rem;

  @media (min-width: 891px) {
    display: none;
  }
`;

const SlideContent3 = styled.div`
  font-family: ${(props) => props.theme.font.secondary};
  ${(props) => props.theme.font_size.xsmall};
  z-index: 1050;
  background: white;
  color: #222;
  display: block;
  line-height: 1.6;
  width: 100%;
  padding: 0.5rem;
  padding-top: 0;
  max-width: 100vw;

  @media (min-width: 891px) {
    display: none;
  }
`;

const CarsCarousel2 = styled(CarsCarousel)`
  max-width: calc(100vw - 1rem);
`;

const Grid = styled.div`
  width: 100%;
  z-index: 10;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "text" "form";
  align-items: stretch;
  align-content: stretch;
  @media (min-width: 891px) {
    align-items: center;
    align-content: center;
    grid-template-columns: 1fr 450px;
    grid-template-areas: "text form";
    grid-gap: 1rem;
    height: 500px;
  }
  @media (min-width: 991px) {
    grid-gap: 2rem;
    grid-template-columns: 1fr 520px;
  }
`;

const ReservationBoxWrapper = styled.div`
  width: 100%;
  z-index: 10;
  grid-area: form;
  @media (max-width: 890px) {
    > div {
      box-shadow: none;
      border-radius: 0;
    }
  }
  @media (min-width: 891px) {
    grid-column: 2;
    margin: 40px 0;
    align-self: center;
  }
`;

const PadlessContainer = styled(Container)`
  padding: 0;
`;

export default HeaderGdansk;
